import React from 'react'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAuth, useSigninCheck } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

export const signOut = auth => auth.signOut().then(() => console.log('signed out'));

const signIn = async auth => {
  const provider = new GoogleAuthProvider();

  await signInWithPopup(auth, provider);
}

export const SignInForm = () => {
  const auth = useAuth();

  return (
    <button
      className="btn btn-primary"
      onClick={() => signIn(auth)}
    >
      <FontAwesomeIcon icon={faGoogle} />
      <span className='ms-1'>Sign-in with Google</span>
    </button>
  );
};

export const AuthWrapper = ({ children, fallback }) => {
  const { data: signInCheckResult } = useSigninCheck();

  if (!children) {
    throw new Error('Children must be provided');
  }

  if (signInCheckResult.signedIn === true) {
    return children
  } else {
    return fallback;
  }
};
