import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { doc, updateDoc } from 'firebase/firestore'
import { useUser, useFirestore, useFirestoreDocData } from 'reactfire'

import SongsList from '../../components/playlist/SongsList'
import { deepSetValue } from '../../utils/Utils'

const VALUES_MAP = {
  "true": Boolean(true),
  "false": Boolean(false)
}

function ButtonsToolbar({onSubmit, backUrl}) {
  return(
    <div className='btn-toolbar justify-content-between'>
      <button
        className='btn btn-primary'
        onClick={onSubmit}
      >
        Save
      </button>
      <a
        className="btn btn-link pull-right"
        href={backUrl}
      >
        Back
      </a>
    </div>
  )
}

function Edit(props) {
  const { id } = useParams();
  let navigate = useNavigate()

  const docRef = doc(useFirestore(), "playlists", id)

  /* Auth check */
  const currentUser = useUser()
  if (currentUser === null) navigate('/')
  /* /Auth check */

  let {data: docData} = useFirestoreDocData(docRef, {idField: 'id'})
  let [data, setData] = useState(docData)

  const onFieldChange = (field) => (event) => {
    let value = event.target.value
    let newData = Object.assign({}, data)

    if (!isNaN(value * 1)) value = value * 1
    if (VALUES_MAP[value] !== undefined) value = VALUES_MAP[value]

    deepSetValue(newData, field, value)
    setData(newData)
    console.log('onFieldChange', field, value, data)
  }

  const onItemsChanged = (items) => {
    let newData = Object.assign({}, data)
    newData.songs = items
    console.log('onItemsChanged', items)
    setData(newData)
  }

  const onSubmit = () => {
    updateDoc(docRef, data)
  }

  console.log('playlist', data)
  return (
    data && <>
      <div className="form-group">
        <input
          className='form-control'
          onChange={onFieldChange('name')}
          type='text'
          value={data.name}
        />
      </div>
      <div className="form-group">
        <select
          className="form-control"
          onChange={onFieldChange('isPublic')}
          value={Boolean(VALUES_MAP[data.isPublic])}
        >
          <option value={Boolean(true)}>Public</option>
          <option value={Boolean(false)}>Private</option>
        </select>
      </div>
      <div className='list-group list-group-flush mt-3'>
        <SongsList
          items={data.songs}
          onChange={onFieldChange}
          onItemsChanged={onItemsChanged}
        />
      </div>

      <ButtonsToolbar onSubmit={onSubmit} backUrl={`/playlists/${id}`} />
    </>
  )
}

export default Edit
