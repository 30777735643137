import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { collection, query, where, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'

import ItemsList from './ItemsList'
import Highlight from './Highlight'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const COLLECTION_NAME = 'songs';

function ListSongs({authorId}) {
  const {data: currentUser} = useUser()

  const firestore = useFirestore()
  const collectionRef = collection(firestore, COLLECTION_NAME)
  const queryRef = collectionQuery(collectionRef, authorId)
  const { status, data } = useFirestoreCollectionData(queryRef, { idField: 'id' })

  if (status === 'loading')
  {
    return <p>Loading Songs</p>
  }

  // console.log('ListSongs')
  return (
    <ItemsList
      fields={['title']}
      items={data || []}
      renderItem={renderItem}
    >
      <h2>
        Songs
        {currentUser &&
          <NavLink to="/songs/new">
            <FontAwesomeIcon icon={faPlus} className="ms-3" />
          </NavLink>}
      </h2>
    </ItemsList>
  )
}
export default ListSongs

function renderItem (searchQuery) {
  return function (item, index) {
    let {id, title, translations} = item
    let titles = (translations && translations.map &&
      translations.map((tran) => tran.title )) || []

    return(
      <NavLink
        to={`/songs/${id}`}
        key={`song-${index}-${id}`}
        className="list-group-item px-0"
      >
        <Highlight text={title} part={searchQuery} />
        {titles && <div><small className="text-muted">{titles.join(', ')}</small></div>}
      </NavLink>
    )
  }
}

function collectionQuery (collection, id) {
  return id ?
    query(collection, where('author', '==', `/authors/${id}`), orderBy('title', 'asc')) :
    query(collection,                                          orderBy('title', 'asc'))
}
