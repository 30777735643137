import React from 'react'

const Highlight = ({text, part}) => {
  let index = text.toUpperCase().indexOf(part.toUpperCase())

  return(
    <>
      {text.substring(0, index)}
      {part.length > 0 && <span className='highlight'>{text.substring(index, index + part.length)}</span>}
      {text.substring(index + part.length)}
    </>
  )
}

export default Highlight
