import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData, useUser } from 'reactfire';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { RowBig } from '../../utils/Utils';

function Edit(props) {
  const { id } = useParams()
  let navigate = useNavigate()

  /* Auth check */
  const {status: userStatus, data: currentUser} = useUser()
  if (userStatus!== 'loading' && currentUser === null) navigate('/')
  /* /Auth check */

  const authorRef = doc(useFirestore(), 'authors', id)
  const { data: author } = useFirestoreDocData(authorRef, { idField: 'id' })
  const [data, setData] = useState(author)

  const changeField = ({field, value}) => {
    let newData = Object.assign({}, data)
    newData[field] = value
    setData(newData)
  }

  const onFieldChange = (field) => (event) => changeField({field, value: event.target.value})
  const onDelete = (event) => {
    event.preventDefault()
    window.confirm('Are you sure?!') && deleteDoc(authorRef).then(() => navigate('/authors'))
  }

  const saveData = () => updateDoc(authorRef, {...data, updatedBy: currentUser.uid})

  return (
    <>
      <RowBig>
        <div className="col">
          <h2 className="input-group">
            <NavLink to="/authors" className="me-3">
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavLink>
            <input
              className="form-control"
              type="text"
              onChange={onFieldChange('name')}
              value={data.name}
              placeholder="Name..."
            />
          </h2>
          <div>
            <textarea
              className="form-control"
              onChange={onFieldChange('description')}
              placeholder="Description..."
              value={data.description}
            />
          </div>
        </div>
      </RowBig>
      <RowBig>
        <div className='btn-group'>
          <button
            className="btn btn-primary btn-block"
            onClick={saveData}
            >
            Save
          </button>
          <NavLink to={`/authors/${data.id}`} className="btn btn-outline-secondary">
            Back
          </NavLink>
        </div>
        <button
          className='btn btn-danger float-end'
          onClick={onDelete}
        >Delete</button>
      </RowBig>
    </>
  )
}

export default Edit
