import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import { collection, doc, query, orderBy, updateDoc, deleteDoc } from 'firebase/firestore';
import { useUser, useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'

import SongTranslationsEdit from '../../components/SongTranslationsEdit'
import LinksEdit from '../../components/LinksEdit'
import FilesEdit from '../../components/FilesEdit'
import Breadcrumbs from '../../components/Breadcrumbs'
import { RowBig, populateTranslations, buildAuthorDocId } from '../../utils/Utils'

function Edit() {
  const { id, transpose } = useParams()
  const firestore = useFirestore()
  let navigate = useNavigate()

  /* Auth check */
  const {status: userStatus, data: currentUser} = useUser()
  if (userStatus!== 'loading' && currentUser === null) navigate('/login')
  /* /Auth check */

  let [translationIndex, setTranslationIndex] = useState(transpose || 0)

  const authorsRef = collection(firestore, 'authors')
  const { data: authors } = useFirestoreCollectionData(query(authorsRef, orderBy('name', 'asc')),
                                                      { idField: 'id' })

  const songRef = doc(firestore, 'songs', id)
  const { data: song } = useFirestoreDocData(songRef, { idField: 'id' })

  let authorRef = doc(firestore, (song && buildAuthorDocId(song.author)) || '/authors/NO_AUTHOR')
  let { data: authorData } = useFirestoreDocData(authorRef, { idField: 'id' })

  let [data, setData] = useState(song),
    [author, setAuthor] = useState(authorData)

  if (!song) {
    navigate('/songs')
    return null
  }

  const onLinksChanged = (newLinks) => setData(Object.assign({}, data, { links: newLinks }))

  const changeField = ({index, arrayName, field, value}) => {
    console.log('changeField', index, arrayName, data[arrayName], field, value)
    let newData = Object.assign({}, data)
    if (!index) {
      newData[field] = value
    } else {
      let newArr = newData[arrayName] || []
      newArr[index - 1][field] = value
      newData[arrayName] = newArr
    }
    setData(newData)
  }

  const onFieldChange =
    (index) =>
      (field) =>
        (event) => changeField({index, arrayName: 'translations', field, value: event.target.value})

  const onAuthorChange = (event) => {
    let newAuthor = authors.find((a) => a.id === event.target.value)
    setAuthor(newAuthor)
  }

  const addTranslation = () => {
    let newData = Object.assign({}, data)
    newData.translations.push({
      title: `New Title ${data.translations.length}`,
      body: '', lang: ''
    })
    setTranslationIndex(data.translations.length)
    setData(newData)
  }

  const removeTranslation = () => {
    let newData = Object.assign({}, data)
    newData.translations.splice(translationIndex - 1, 1)
    setTranslationIndex(0)
    setData(newData)
  }

  const switchTranslation = (event) => setTranslationIndex(parseInt(event.target.value))

  const saveData = () => {
    let newData = Object.assign({}, data)
    newData.author = author ? `/authors/${author.id}` : null

    updateDoc(songRef, {...newData, updatedBy: currentUser.uid})
  }

  const onDelete = (event) => {
    event.preventDefault()
    window.confirm('Are you sure?!') && deleteDoc(songRef).then(() => navigate('/songs'))
  }

  let translations = populateTranslations(data)

  console.log('edit', data, author)

  return (
    <Fragment>
      <Breadcrumbs songId={id} />
      <RowBig>
        <select
          className="form-select"
          onChange={onAuthorChange}
          defaultValue={(author && author.id) || ''}
        >
          {[{id: null, name: '-- Select Author --' }, ...authors]
              .map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
        </select>
      </RowBig>
      <div className="form-group row mb-3">
        <div className="col">
          <input
              className="form-control"
              type="text"
              onChange={onFieldChange(null)('album')}
              value={(data && data.album) || ''}
              placeholder="Album..."
            />
        </div>
        <div className="col-3">
          <input
              className="form-control"
              type="text"
              onChange={onFieldChange(null)('year')}
              value={(data && data.year) || ''}
              placeholder="Year..."
            />
        </div>
      </div>
      <RowBig>
        <SongTranslationsEdit
          addTranslation={addTranslation}
          removeTranslation={removeTranslation}
          index={translationIndex}
          onFieldChange={onFieldChange}
          switchTranslation={switchTranslation}
          translations={translations}
        />
      </RowBig>
      <RowBig>
        <LinksEdit data={data} onChange={onLinksChanged}/>
      </RowBig>

      <RowBig>
        <FilesEdit id={id}/>
      </RowBig>

      <RowBig>
        <button
          className="btn btn-primary"
          onClick={saveData}
        >
          Save
        </button>
        <a href={`/songs/${id}`} className="btn btn-link">
          Cancel
        </a>

        <button
          className='btn btn-danger float-end'
          onClick={onDelete}
        >Delete</button>
      </RowBig>
    </Fragment>
  )
}

export default Edit
